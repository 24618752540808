@if (!isEdit && !startCourse) {
    <div class="my-4 px-20">
        <prism-title additionalClasses="text-4xl" [title]="course?.name"></prism-title>
        <div class="flex justify-between items-center mb-4">
            <p class="text-xl font-bold">By {{ course?.author }}</p>
            @if (isAdmin) {
                <div class="flex">
                    <prism-btn btnClass="btn-white" (click)="toggleEdit()">
                      <i class="uil uil-edit text-lg"></i>
                      <span class="text-lg"> Edit Course</span>
                    </prism-btn>
                </div>
            }
        </div> 
        <img class="w-full h-auto max-h-72" [src]="blobImgUrl" alt="course image">
        <prism-title additionalClasses="text-xl pt-3" title="Attachments"></prism-title>
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div class="col-span-1 lg:col-span-3 flex flex-wrap gap-3 items-center">
                @if (attachments.length > 0) {
                    @for (attachment of attachments; track $index) {
                        @if (user.userId) {
                            <a class="flex gap-2 bg-light-purple w-full lg:w-fit p-2 rounded-lg justify-between" 
                                [href]="user.userId ? attachment.blobUrlSas : null" target="_blank">
                                 <p class="text-base font-semibold pr-2">{{attachment.fileName}}</p>
                                 <i class="uil uil-import text-xl text-dark-purple"></i>
                            </a>
                        } @else {
                            <a class="flex gap-2 bg-light-purple w-full lg:w-fit p-2 rounded-lg justify-between">
                                 <p class="text-base font-semibold pr-2">{{attachment.fileName}}</p>
                                 <i class="uil uil-import text-xl text-dark-purple"></i>
                            </a>
                        }
                    }
                } @else {
                    <p class="font-bold italic">No attachments available</p>
                }
                
            </div>
            @if (user.userId) {
                <div class="col-span-1 gap-1 flex flex-wrap flex-col lg:flex-row pb-4">
                    <prism-btn [disabled]="!viewCertificate || !hasJournalEntries" btnClass="btn-purple flex-full w-full text-nowrap" (click)="viewPrintCertificate()">Download Certificate
                        <i class="uil uil-import text-xl text-white"></i>
                    </prism-btn>
                    <prism-btn btnClass="btn-purple flex-full pt-2 w-full text-nowrap" (click)="toggleStartCourse()">{{ btnText }} Course</prism-btn>
                </div>
            }
        </div>
        <prism-title additionalClasses="text-xl pt-3" title="Class Description"></prism-title>
        <p>
            {{ course?.description}}
        </p>
        <div class="my-2">
            @if (user.roleId === role.User) {
                <prism-title additionalClasses="text-xl pt-3" title="Journal"></prism-title>
                @if (userEntries) {
                    @for (entry of userEntries; track entry) {
                        <p class="font-bold text-lg">Me: {{ entry.entry }}</p>
                        <p class="text-xs mb-4">Submitted on {{entry.dateSubmitted}}</p>
                    }
                }
                <p class="text-dark-purple font-bold my-2">
                    Please check your journal entry before you submit. Your journal will not be displayed publicly. Please do not share any private information or sensitive data.
                </p>
                <form [formGroup]="journalForm" (ngSubmit)="submitJournalEntry()">
                    <prism-input
                        [control]="journalForm.get('entry')"
                        label="Journal Entry"
                        placeHolder="Enter journal entry"
                        [inputType]="InputType.Textarea"
                        [maxLength]="2000">
                    </prism-input>
                    <prism-btn btnClass="btn-purple mt-4">Submit</prism-btn>
                </form>
            }
            @if (user.roleId === role.DistrictAdmin || user.roleId === role.SystemAdmin ) {
                <prism-btn btnClass="btn-purple my-2" (click)="downloadJournals()">Download Journal</prism-btn>
            }
        </div>
    </div>
} @else if (isEdit) {
    <prism-course-edit 
        [course]="course" 
        [attachments]="attachments" 
        (isEditChange)="updateCourse()" 
        (updateCourseAttachments)="updateAttachments()">
    </prism-course-edit>
} @else if (startCourse) {
    <div class="mt-4">
        <prism-course-view [courseName]="courseName"></prism-course-view>
        <div class="flex flex-nowrap justify-center items-center mb-5" [formGroup]="completeForm">
            <label nz-checkbox formControlName="isCompleted" [nzDisabled]="userCourse?.isCompleted" class="cursor-pointer border-2 border-dark-purple p-2">
                I have reviewed and accepted the terms of the Employee Acceptable Use Policy and Computer Use Agreements.
            </label>
        </div>
        <div class="flex flex-nowrap justify-center items-center mb-5">
            <prism-btn btnClass="btn-purple flex-fit pt-2 w-48 text-nowrap" [disabled]="userCourse?.isCompleted ?? false" (click)="submit()">Finish</prism-btn>
        </div>
    </div>    
}

import { Component, EventEmitter, Input, Output, Signal, effect, inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Role } from '@core/enums/roles';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { INavbarItems } from '@core/interfaces/inavbar-items';
import { Router } from '@angular/router';
import { Navbar } from '@core/enums/navbar';
import { take } from 'rxjs';
@Component({
  selector: 'prism-navbar',
  standalone: false,
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  @Input() openSideMenu: boolean = false;
  private router = inject(Router);
  private authService = inject(AuthService);
  @Output() openLoginModal: EventEmitter<any> = new EventEmitter();
  currentUser: Signal<ILoginResponse | null> = this.authService.currentUserInfo;
  navbarItems: INavbarItems[] = [];
  isLoggedIn: boolean = this.authService.checkLogin();
  Navbar = Navbar;
  role = Role;
  logoUrl = 'assets/images/prism-logo-secondary-rgb.png';
  iconImgUrl: string = 'assets/images/Icon feather-user.svg';
  userFullName: string ='';
  activeLink: string = '';
  dropdownVisible = false;

  constructor() {
    effect(() => {
      this.setMenuItems();
      this.authService.checkLogin();
      this.iconImgUrl = this.isLoggedIn && this.currentUser() ?
        'assets/images/Icon feather-user.svg' :
        'assets/images/login icon.svg';
      this.userFullName = this.currentUser()?.fullName ?? '';
    });

    this.router.events
    .pipe(
    )
    .subscribe(() => {
      this.activeLink = this.isSpecifiedRoute() ? this.router.url : '';
    });
  }

  setMenuItems(): void {
    if (this.isLoggedIn) {
      if (this.currentUser()?.roleId === Role.User) {
        this.navbarItems = [
          { label: 'Home', link: '/dashboard' }
        ];
      } else if (this.currentUser()?.roleId === Role.DistrictAdmin || this.currentUser()?.roleId === Role.SystemAdmin) {
        this.navbarItems = [
          { label: 'Home', link: '/dashboard' },
          { label: 'Courses', link: '/courses' },
          { 
            label: 'Resources', 
            link: '/resources', 
            childItems:
              [
                { label: 'External Resources', link: 'resources/external' },
                { label: 'Impact Videos', link: 'resources/impact-video' },
              ] 
          },
          { 
            label: 'Management',
            link: '/user-management',
            childItems:
              [
                { label: 'User Management', link: '/user-management' },
                { label: 'District Management', link: '/district-management' },
                { label: 'School Management', link: '/school-management' },
              ] 
          },
          { label: 'Reports', link: '/report' }
        ];
      }
    } else {
      this.navbarItems = [
         { label: 'Home', link: '/home' },
         { label: 'About', link: '/about' },
         { label: 'Development', link: '/development' },
         { label: 'Implementation', link: '/implementation' },
         { label: 'FAQ', link: '/FAQ' },
      ];
    }
  }
  
  navigate(url: string): void {
    this.router.navigateByUrl(url);
  }

  onLoginClick(): void {
    this.openLoginModal.emit();
  }

  isActive(link: string): boolean {
    return this.isSpecifiedRoute() && this.activeLink === link;
  }

  isSpecifiedRoute(): boolean {
    return this.navbarItems?.some(item => item.link === this.router.url) || this.router.url === '/contact-us';
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

  handleDropdownKeyDown(event: KeyboardEvent, dropdownMenu: any): void {
    if (event.key === 'Enter' || event.key === ' ' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.dropdownVisible = true;
      
      setTimeout(() => {
        const firstMenuItem = document.querySelector('li[nz-menu-item]') as HTMLElement;
        if (firstMenuItem) {
          firstMenuItem.focus();
        }
      }, 100);
    }
  }

  handleMenuItemKeyDown(event: KeyboardEvent, childItem: any): void {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault();
        this.navigate(childItem.link);
        this.dropdownVisible = false;  // Close after selection
        break;
      case 'ArrowDown':
        event.preventDefault();
        const nextSibling = (event.target as HTMLElement).closest('li')?.nextElementSibling as HTMLElement;
        if (nextSibling) {
          nextSibling.focus();
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        const previousSibling = (event.target as HTMLElement).closest('li')?.previousElementSibling as HTMLElement;
        if (previousSibling) {
          previousSibling.focus();
        }
        break;
      case 'Escape':
        event.preventDefault();
        this.dropdownVisible = false;
        const dropdownTrigger = document.querySelector('[nz-dropdown]') as HTMLElement;
        if (dropdownTrigger) {
          dropdownTrigger.focus();
        }
        break;
    }
  }

  logout(): void {
    this.authService.logout();
  }
}

<div id="navbar" class="w-full hidden lg:block mx-auto px-20 py-2 bg-[#FBFBFB]">
  <nav role="navigation" aria-label="Main navigation"> <!-- Added semantic nav element -->
    <div class="flex justify-between">
      <ul class="flex gap-3 text-base">
        <li *ngFor="let menuItem of navbarItems" class="text-black mr-6">
          @if (!menuItem.childItems) {
            <a [href]="menuItem.link" 
               tabindex="0" 
               class="hover:text-gray-400 font-semibold focus:outline-none focus:ring-2 focus:ring-dark-purple" 
               [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive(menuItem.link) }">
              {{ menuItem.label }}
            </a>
          }
          @else {
            <nz-dropdown 
              nzTrigger="ßclick">
              <a nz-dropdown 
                 [nzDropdownMenu]="menu" 
                 tabindex="0"
                 class="font-bold focus:outline-none focus:ring-2 focus:ring-dark-purple cursor-pointer" 
                 [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive(menuItem.link) }"
                 (keydown)="handleDropdownKeyDown($event, menu)"
                 (click)="toggleDropdown()"
                 role="button"
                 aria-haspopup="true"
                 [attr.aria-expanded]="dropdownVisible">
                 {{ menuItem.label }}
                 <i class="uil uil-angle-down text-md text-dark-purple" aria-hidden="true"></i>
              </a>           
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu 
                    nzSelectable 
                    role="menu" 
                    class="outline-none">
                  @for (childItem of menuItem.childItems; track $index) {
                    <li nz-menu-item 
                        role="menuitem"
                        [attr.tabindex]="0"
                        (keydown)="handleMenuItemKeyDown($event, childItem)"
                        (click)="navigate(childItem.link)"
                        class="focus:outline-none focus:ring-2 focus:ring-dark-purple">
                      {{ childItem.label }}
                    </li>
                  }
                </ul>
              </nz-dropdown-menu>
            </nz-dropdown>
          }
        </li>
      </ul>
      <div class="text-black" ng-container>
        @if (!isLoggedIn || currentUser()?.roleId == role.User) {
          <a href="/contact-us" 
             tabindex="0"
             class="hover:text-gray-400 font-semibold font-quicksand focus:outline-none focus:ring-2 focus:ring-dark-purple" 
             [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive('/contact-us') }">
            Contact Us
          </a>
        }
      </div>
    </div>
  </nav>
</div>

<!-- Mobile Menu -->
<nz-layout *ngIf="openSideMenu" class="fixed z-[200]">
  <nz-sider nzWidth="200px">
    <nav role="navigation" aria-label="Mobile navigation">
      <div class="flex justify-center">
        <a class="m-4" 
           href="/home" 
           tabindex="0"
           class="focus:outline-none focus:ring-2 focus:ring-dark-purple"
           [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-gray-400': isActive('/home') }">
          <img class="w-20" [src]="logoUrl" alt="Prism Logo">
        </a>
      </div>
      <ul nz-menu nzMode="inline" [nzInlineIndent]="1" role="menu">
        <div class="flex flex-row flex-wrap place-items-start">
          <img [src]="iconImgUrl" class="w-6 ml-4 mr-2 mt-2" alt="User Icon">
          <div>
            @if (!isLoggedIn) {
              <a class="font-bold flex ml-4 my-6" (click)="onLoginClick()">Log in</a>
            }
            @else {
              <li class="mt-4"
                nz-submenu 
                [nzTitle]="userFullName">
                <ul class="hover:text-gray-400">
                  <li nz-menu-item><a href="/course-history">Course History</a></li>
                  <li nz-menu-item><a  href="/account-settings">Account Settings</a></li>
                </ul>
              </li>
            }
          </div>
        </div>
        <div *ngFor="let menuItem of navbarItems">
          @if (!menuItem.childItems) {
            <li nz-menu-item>
              <a [href]="menuItem.link" class="hover:text-gray-400 font-semibold ml-2" [ngClass]="{ 'underline !decoration-solid !decoration-dark-purple decoration-3 text-dark-purple': isActive(menuItem.link) }">{{ menuItem.label }}</a>
            </li>
          }
          @else {
            <li class="hover:text-gray-400 font-semibold ml-2"
              nz-submenu 
              [nzTitle]="menuItem.label">
              <ul class="hover:text-gray-400 ml-2">
                @for (childItem of menuItem.childItems; track $index) {
                  <li nz-menu-item (click)="navigate(childItem.link)">{{ childItem.label }}</li>
                }
              </ul>
            </li>
          }
        </div>
        @if (!isLoggedIn || currentUser()?.roleId == role.User) {
          <li nz-menu-item class="hover:text-gray-400 font-semibold ml-2">
            <a href="/contact-us" [ngClass]="{ 'underline !decoration-solid !decoration-blue-800 decoration-3 text-dark-purple': isActive('/contact-us') }">Contact Us</a>
          </li>
        }
        @if (isLoggedIn) {
          <li nz-menu-item class="hover:text-gray-400 font-semibold ml-2">
            <a (click)="logout()">Log Out</a>
          </li>
        }
      </ul>
    </nav>
  </nz-sider>
</nz-layout>